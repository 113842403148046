@import '../../../../scss/common';
@import '../../../../scss/variables/colors';
@import '../../../../scss/animation';
@import "~emoji-mart/css/emoji-mart.css";

.rcw-conversation-container {
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 2px 10px 1px $grey-3;

  &.active {
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  &.hidden {
    z-index: -1;
    pointer-events: none;
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
}

.rcw-conversation-container-fs {
  border-radius: 0px;
  box-shadow: 0px 2px 10px 1px $grey-3;
  @include conversation-container-fs;

  &.active {
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  &.hidden {
    z-index: -1;
    pointer-events: none;
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
}

.emoji-mart-preview {
  display: none;
}

.emoji-mart-scroll {
  height: 25vh;
  font-family: 'Source Sans Pro', 'Segoe UI', sans-serif;
} 

// .rcw-full-screen {
//   .rcw-conversation-container {
//     @include conversation-container-fs;
//   }
// }

// @media screen and (max-width: 800px) {
//   .rcw-conversation-container {
//     @include conversation-container-fs;
//   }
// }
.message-rating-loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba($grey-0, 0.5);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}