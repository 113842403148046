/* 
* {
  box-sizing: border-box;
} */

body {
  font-family: "Source Sans Pro", "Segoe UI", sans-serif !important;
  font-size: small;
  line-height: 1;
  color: #24292e;
  /* background-color: #fff; */
}

label.email {
  margin-right: 80px;
  font-size: 20px;
}

.app {
  margin: 2rem;
}

a {
  color: #08c;
}

code {
  background: #eee;
  padding: 0.1rem;
  font-family: "Menlo";
  font-size: 13px;
  color: #ff00aa;
}

input {
  padding: 0.5rem;
  font-size: 14px;
  font-family: 'Source Sans Pro', 'Segoe UI', sans-serif !important;
  width: 250px;
  display: block;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin: 0;
}

input:focus {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 3px rgba(0, 126, 255, 0.1);
  outline: none;
}

input.error {
  border-color: red;
}

.options-label-standard{
  color: grey;
}

.options-label-error{
  color: #e54545 !important;
}

.options-wrapper-standard{
  border: 1px solid #cecece;
}

.options-wrapper-error{
  border: 1px solid #e54545;
}

.options-border-top-standard{
  border-top: 1px solid #cecece;
}

.options-border-top-error{
  border-top: 1px solid #e54545;
}


label {
  font-weight: 500;
  font-size: 14px !important;
  display: block;
  margin-bottom: 0.5rem;
  font-family: 'Source Sans Pro', 'Segoe UI', sans-serif !important;
}


.input-feedback {
  color: red;
  margin: 0px;
  display: flex;
  margin-left: 85px;
  margin-bottom: 10px;
  font-size: 10px;
}


div.inputboxes-name, div.inputboxes-phone, div.inputboxes-email {
  margin-bottom: 26px !important
}

div.inputboxes-name::after {
  margin-bottom: 0px
}

/* button {
  max-width: 150px;
  margin: 20px 0;
  padding: 12px 20px;
  border-style: none;
  border-radius: 5px;
  background-color: #08c;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 17px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
} */

button:disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

button + button {
  margin-left: 0.5rem;
}

button.outline {
  text-transform: none;
  max-width: 278px;
  /* margin: 6px; */
  padding: 10px 20px;
  border-style: none;
  border-radius: 4px;
  background-color: #08c;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  font-family: "Source Sans Pro";
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  background-color: #000000;
  display: inline-block;
}

