img.submit-checkmark {
  height: 200px;
  width: 200px;
  margin-bottom: -50px;
  margin-top: -20px;
}

.confirmation-container {
  width: 100%;
  height: 40vh;
  font-size: 16px;
  display: inline-grid;
  align-items: center;
}


button.to-contact-form {
  text-transform: none;
  margin-top: 30px;
  border-radius: 5px;
  background-color: #000000;
  padding: 6px 20px;
  color: #ffffff;
  font-size: 15px;
  font-family: 'Source Sans Pro', 'Segoe UI', sans-serif;
  font-weight: 500;
  border-style: none;
  /* border: 1px solid black; */
  cursor: pointer;
  width: 70%;
  
  /* text-transform: none;
  width: 70%;
  margin: 6px;
  padding: 10px 20px;
  border-style: none;
  border-radius: 4px;
  background-color: #08c;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  background-color: #000000;
  display: inline-block; */
}
