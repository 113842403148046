@import "../../../../../../scss/variables/colors";

.quick-buttons-container {
  background: $white;
  overflow-x: none;
  overflow-y: visible;
  white-space: nowrap;
  width: 100%;
  // padding: 10px;

  .quick-buttons {
    list-style: none;
    padding: 0;
    margin: 0 0 0 50px;
    text-align: left;
    display: flex;
    flex-wrap: wrap;

    .quick-list-button {
      display: inline-block;
      margin: 8px 5px 8px 0;
      max-width: 100%;
    }
  }

  // @media screen and (max-width: 800px) {
  //   padding-bottom: 15px;
  // }
}
