@import "../../../../../../scss/variables/colors";
@import "../../../../../../scss/common";

.company-footer {
  line-height: 25px;
  width: 100%; 
  padding-top: 5px; 
  padding-bottom: 5px; 
  color: grey;
  border-top: 1px solid #dcdce4;
  font-family: 'Source Sans Pro','Segoe UI',sans-serif;
  font-weight: 600; 
  font-size: 14px; 
  background-color:#ffffff;
  border-radius: 0 0 10px 10px;
  display: inline-flex; 
  justify-content: center;
  align-items: center;
}

.company-footer-fs {
  line-height: 25px;
  width: 100%; 
  padding-top: 5px; 
  padding-bottom: 5px; 
  color: grey;
  border-top: 1px solid #dcdce4;
  font-family: 'Source Sans Pro','Segoe UI',sans-serif;
  font-weight: 600; 
  font-size: 14px; 
  background-color:#ffffff;
  display: inline-flex; 
  justify-content: center;
  align-items: center;
}