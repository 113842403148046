img.disconnected-checkmark {
    height: 100px;
    width: 100px;
    /* margin-bottom: -50px; */
    margin-top: -20px;
}

.error-container {
    width: 100%;
    height: 35vh;
    font-size: 16px;
    display: inline-grid;
    align-items: center;
}
  