.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /*animation: App-logo-spin infinite 20s linear;*/
	height: 400px;
	width: 400px;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  width: 60px;
  margin-right: var(--PhoneInputCountrySelect-marginRight);
  justify-content: space-evenly;
}

.Welcome {
  height: 22px;
  font-family: 'Source Sans Pro', 'Segoe UI', sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #2f2f32;
  margin: 20px 0 0 0;
}

.Please-complete-the {
  height: 26px;
  margin: 0px 0px 20px 0px;
  font-family: 'Source Sans Pro';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.17;
  letter-spacing: normal;
  color: #2f2f32;
}

.MuiIconButton-root{
  border-radius: 5% !important;
}

.response-document{
  display: flex;
  justify-content: flex-start;
  width: 300px;
}

.client-document{
  display: flex;
  justify-content: flex-end;
  width: 300px;
}

.response-document-image{
  width: 50%;
}

.client-document-image{
  width: 50%;
}

.App-link {
  color: #FBD207;
}

::-webkit-scrollbar{
  width: 4px;
}

.emoji-mart-scroll::-webkit-scrollbar {
  width: 6px;
}

.PhoneInputCountrySelect::-webkit-scrollbar {
  width: 8px;
}

.PhoneInputCountrySelect::-webkit-scrollbar-thumb, .emoji-mart-scroll::-webkit-scrollbar-thumb {
  border-radius: 5px
}

.rcw-messages-container::-webkit-scrollbar {
  width: 6px
}

::-webkit-scrollbar-track{
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb{
  background-color: #dcdce4;
  border-radius: 2.5px
}

::-webkit-scrollbar-thumb:hover{
  background-color: #909093;
  
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(15px, -12px) scale(0.9) !important;
}

.MuiFormControlLabel-label {
  font-family: 'Source Sans Pro', 'Segoe UI', sans-serif !important;
  font-size: 14px !important;
}

.MuiFormControlLabel-label-root :hover {
  border: '1px solid #000000' !important;
}

.MuiInputLabel-shrink {
  transform: translate(5px, -5px) scale(0.9) !important;
  transform-origin: none !important;
}

.MuiOutlinedInput-input {
  padding: 14px 16px !important;
}

.MuiFormLabel-root {
  font-size: 14px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .MuiFormHelperText-root {
  font-family: 'Source Sans Pro', 'Segoe UI', sans-serif !important;
  letter-spacing: 0 !important;
}

.MuiOutlinedInput-root {
  border-radius: 8px !important
}

.MuiInputBase-input {
  font-size: 14px !important;
}

.MuiInputLabel-outlined.MuiInputLabel-marginDense {
  font-size: 14px !important;
}

.MuiOutlinedInput-inputMarginDense {
  padding-top: 13px !important;
  padding-bottom: 13px !important
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #e54545 !important;
}

.MuiInputBase-root {
  color: black !important
}
*/