@import "../../../../../../../../scss/variables/colors";

button.quick-button {
  background: white;
  // border: 2px solid #fbd207;
  // font-weight: bold;
  border: 2px solid black;
  color: black;
  padding: 5px 10px;
  cursor: pointer;
  outline: 0;
  border-radius: 20px;
  font-family: "Source Sans Pro";
  font-size: 14px;
  text-align: left;
  max-width: 100%;
  white-space: normal;

  &:active {
    // background: #fbd207;
    color: $white;
  }

  &:hover {
    background-color: #000000;
    color: white;
  }
}


