@import '../../../../../../scss/variables/colors';
@import '../../../../../../scss/common';

.message-rating-comment-popup-back {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba($grey-0, 0.5);
  opacity: 0;
  transition: opacity 0.3s;

  &.showing {
    opacity: 1;
  }
}

.message-rating-comment-popup {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Source Sans Pro", "Segoe UI", sans-serif;
  opacity: 0;
  transition: opacity 0.3s;
  
  .message-rating-comment-container {
    position: relative;
    width: 80%;
    height: 30%;
    min-height: 300px;
    background-color: $white;
    border-radius: 8px;
    overflow: hidden;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    .header {
      flex: 1 0 auto;
      box-sizing: border-box;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 22px;
      font-weight: 700;
      padding: 12px 0;

      .close-button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        outline: 0;
        border: 0;
        background: transparent;
        cursor: pointer;

        img {
          width: 32px;
          height: 32px;
        }
      }
    }

    .prompt {
      flex: 1 0 auto;
      width: 100%;
      text-align: start;
      font-size: 16px;
    }

    .comment {
      flex: 999 1 auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      
      .comment-box {
        flex: 999 1 auto;
        box-sizing: border-box;
        width: 100%;
        padding: 16px;
        font-size: 16px;
        line-height: 24px;
        resize: none;
        border-radius: 8px;
        border-color: $grey-0;
  
        &:focus {
          outline: 0;
        }
      }

      .comment-count {
        flex: 1 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        font-size: 12px;
        color: $grey-0;
      }
    }

    .action-bar {
      flex: 1 0 auto;
      box-sizing: border-box;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 12px 0;
      gap: 8px;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        outline: 0;
        border: 1px solid black;
        border-radius: 8px;
        padding: 8px 16px;
        background: transparent;
        cursor: pointer;

        &.submit {
          background: black;
          color: $white;

          &.disabled {
            border-color: #F2F2F3;
            background: #F2F2F3;
            color: #A3A3A4;
            cursor: default;
          }
        }
      }
    }
  }

  &.showing {
    opacity: 1;
  }
}