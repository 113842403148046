@import "../../../../../../scss/variables/colors";
@import "../../../../../../scss/common";
@import url("https://www.1001fonts.com/download/font/source-sans-pro.bold.ttf");

.rcw-conversation-container {
  img.rcw-close {
    width: 40px;
    height: 40px;
    text-align: right;
  }

  img.rcw-close:hover {
    background: #ebebeb;
    border-radius: 10px;
  }

  .rcw-header {
    background-color: #ffffff;
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid #dcdce4;
    color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 10px;
    position: relative;
  }

  .rcw-title {
    display: flex;
    align-self: center;
    align-items: center;
    font-size: 24px;
    font-family: 'Source Sans Pro Bold', 'Segoe UI', sans-serif;
    font-weight: bolder;
    margin: 8px 0 0 0;
    color: black;
    text-align: center;
  }

  .rcw-close-button {
    // display: block;
    // position: absolute;
    // right: 10px;
    // top: 15px;
    // width: 40px;
    // outline: none;
    display: block;
    position: absolute;
    right: 10px; 
    top: 15px;
    width: 40px;
    outline: none;
    border: none;
    background-color: white;
    padding: 0;
    cursor: pointer;
    text-align: right;
  }

  .avatar {
    // width: 40px;
    height: 40px;
    //border-radius: 100%;
    margin-right: 3px;
    vertical-align: middle;
  }
}

.rcw-conversation-container-fs {
  img.rcw-close {
    width: 40px;
    height: 40px;
    text-align: right;
  }

  img.rcw-close:hover {
    background: #ebebeb;
    border-radius: 10px;
  }

  .rcw-header {
    background-color: #ffffff;
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid #dcdce4;
    color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 10px;
    position: relative;
  }

  .rcw-title {
    display: flex;
    align-self: center;
    align-items: center;
    font-size: 24px;
    font-family: 'Source Sans Pro Bold', 'Segoe UI', sans-serif;
    font-weight: bolder;
    margin: 8px 0 0 0;
    color: black;
    text-align: center;
  }

  .rcw-close-button {
    // display: block;
    // position: absolute;
    // right: 10px;
    // top: 15px;
    // width: 40px;
    // outline: none;
    display: block;
    position: absolute;
    right: 10px; 
    top: 15px;
    width: 40px;
    outline: none;
    border: none;
    background-color: white;
    padding: 0;
    cursor: pointer;
    text-align: right;
  }

  .avatar {
    // width: 40px;
    height: 40px;
    //border-radius: 100%;
    margin-right: 3px;
    vertical-align: middle;
  }
}

.rcw-full-screen {
  .rcw-header {
    @include header-fs;
  }

  .rcw-title {
    @include title-fs;
  }

  // .rcw-close-button {
  //   @include close-button-fs;
  // }

  .rcw-close {
    @include close-fs;
  }
}

// @media screen and (max-width: 800px) {
//   .rcw-conversation-container {
//     .rcw-header {
//       @include header-fs;
//     }

//     .rcw-title {
//       @include title-fs;
//     }

//     .rcw-close-button {
//       @include close-button-fs;
//       background-color: #ffffff;
//       height: 40px;
//       width: 40px;
//       border-radius: 10px;
//     }

//     .rcw-close {
//       @include close-fs;
//     }
//   }
// }
