body {
  margin: 0;
  font-family: "Source Sans Pro", "Segoe UI", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  font-size: large;
  /* text-decoration: dashed; */
  color: black;
}