@import '../../../../../../../../scss/variables/colors';
@import '../../../../../../../../scss/common';

//redundant?
.rcw-message {
  margin: 10px;
  display: flex;
  white-space: pre-wrap;
  word-wrap: break-word;

  &.rating-enabled {
    margin: 26px 10px;
  }
}

.rcw-message-buttons {
  margin: 10px;
  display: flex;
  white-space: pre-wrap;
  word-wrap: break-word;
  flex-wrap: wrap;

  &.rating-enabled {
    margin: 26px 10px;
  }
}

.rcw-timestamp {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.features-container {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
}

.rcw-client {
  display: flex;
  flex-direction: column;
  margin-left: auto;

  .rcw-message-text {
    // @include message-bubble($turqois-2);

    // white-space: pre-wrap;
    // word-wrap: break-word;
    background-color: #ffeb98;
    border-radius: 20px 0 20px 20px;
    padding: 16px;
    max-width: 70vw;
    text-align: left;
    font-family: "Source Sans Pro", "Segoe UI", sans-serif;
    font-size: 14px;
    line-height: 20px
  }

  .rcw-timestamp {
    align-self: flex-end;
    margin-top: 8px;
  }

  .rating-container {
    display: none;
  }

  .features-container {
    justify-content: flex-end;
  }
}

.rcw-response {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .rcw-message-text {
    // @include message-bubble($grey-2);
    background-color: #f6f7f8;
    border-radius: 0 20px 20px 20px;
    padding: 16px;
    max-width: 70vw;
    text-align: left;
    font-family: "Source Sans Pro", "Segoe UI", sans-serif;
    font-size: 14px;

    .internal-media-buttons {
      margin-top: 4px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
  
      .internal-media-buttons__prefix {
        color: #525253;
        margin-right: 4px;
      }

      .internal-media-buttons__button {
        margin: 2px 4px 2px 0;
      }
    }
  }

  .rating-container {
    display: flex;
    height: 28px;
    justify-content: flex-start;
    align-items: center;

    .rating-button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      outline: 0;
      border: 0;
      background: transparent;
      cursor: pointer;
      padding: 6px;
      margin: 0;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}

/* For markdown elements created with default styles */
.rcw-message-text {
  p {
    margin: 0;
  }

  img {
    width: 100%;
    object-fit: contain;
  }
}

.rcw-avatar {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 10px;
}

.rating-submission-error {
  margin-left: 5px;
  color: #E54545;
}

.cache-container {
  display: flex;
}

.cache {
  font-family: Source Sans Pro;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #828282;
}

