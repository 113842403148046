@import "../../../../../../scss/variables/colors";

.rcw-launcher {
  .rcw-badge {
    position: fixed;
    top: -10px;
    right: -5px;
    background-color: $red;
    color: $white;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    border-radius: 50%;
  }
}

// img.rcw-open-launcher {
//   width: 34px;
//   height: 32px;
//   height: 27px;
// }

img.rcw-close-launcher {
  width: 14px;
  height: 14px;
}
