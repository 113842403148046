@import "../../../../../../../../scss/variables/colors";

button.internal-media-button {
  background-color: #f1f8ff;
  color: #007bff;
  padding: 2px 8px;
  cursor: pointer;
  outline: 0;
  font-family: "Source Sans Pro";
  white-space: normal;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  .internal-media-button__icon {
    flex: 1 0 auto;
  }

  .internal-media-button__label {
    text-align: start;
    flex: 9999 1 auto;
    word-break: break-all;
  }
}


