@import '../../../../../../../../scss/variables/colors';
@import '../../../../../../../../scss/common';

.rcw-snippet {
  @include message-bubble($grey-2);
}

.rcw-snippet-title {
  margin: 0;
}

.rcw-snippet-details {
  border-left: 2px solid $green-1;
  margin-top: 5px;
  padding-left: 10px;
}

.rcw-link {
  font-size: 12px;
}
