@import '../../scss/animation';
@import '../../scss/common';

.rcw-widget-container {
  bottom: 24px;
  display: flex;
  flex-direction: column;
  max-width: 370px;
  position: absolute;
  right: 24px;
  // height: 100%;
  width: 100%;
  z-index: 50;

  &.mobile {
    bottom: 0;
    right: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.rcw-widget-container-fs{
  height: 100%;  
  display: flex;
  flex-direction: column;
  @include widget-container-fs;
}

.rcw-full-screen {
  @include widget-container-fs;
}

// @media screen and (max-width: 800px) {
//   .rcw-widget-container {
//     height: 100%;
//     @include widget-container-fs;
//   }
// }

.rcw-previewer .rcw-message-img {
  cursor: pointer;
}

.rcw-close-widget-container {
  height: max-content;
  width: max-content;
}
