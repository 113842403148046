@import "../../../../../../scss/variables/colors";

.rcw-sender {
  align-items: center;
  background-color: white;
  // border-radius: 0 0 10px 10px;
  display: flex;
  height: max-content;
  max-height: 95px;
  min-height: 45px;
  overflow: hidden;
  padding: 5px 10px;
  position: relative;
  border-top: 1px solid #dcdce4;

  &.expand {
    height: 55px;
  }
}

.rcw-new-message {
  background-color: $white;
  border: 0;
  //border-radius: 5px;
  padding: 5px 8px;
  resize: none;
  width: calc(100% - 45px);
  height: 50px;
  display: block;
  overflow-y: auto;

  &:focus {
    outline: none;
  }

  &.expand {
    height: 40px;
  }
}

.rcw-input {
  display: block;
  //height: 100%;
  line-height: 25px;
  max-height: 74px;
  overflow-y: auto;
  text-align: left;
  user-select: text;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 14px;

  &:focus-visible {
    outline: none;
  }

  &[placeholder]:empty::before {
    content: attr(placeholder);
    color: $grey-0;
  }
}

.rcw-send,
.rcw-picker-btn {
  background: white;
  border: 0;
  cursor: pointer;
  margin: 0;
  width: 30px;
  outline: none;
  z-index: 2;

  .rcw-send-icon {
    height: 29px;
  }
}

button.rcw-send {
  padding-left: 3px;
  margin-bottom: -1px;
  display: flex;
  align-items: center;
}

button.rcw-picker-btn {
  margin-left: 10px;
  display: flex;
  align-items: center;
}

img.rcw-picker-icon {
  height: 27px;
}
.rcw-new-non-text-message {
  //display: inline-block;
  //display: flex;
  //align-items: initial;
  //height: 80px;
  display: flex;
  justify-content: flex-end;
  //width: 40vh;
}

.rcw-message-disable {
  background-color: white;
  cursor: not-allowed;
}

// @media screen and (max-width: 800px) {
//   .rcw-sender {
//     border-radius: 0;
//     flex-shrink: 0;
//   }
// }

.rcw-attachment-preview-cancel-button {
  position: absolute;
  //top: -20%;
  bottom: -10px;
  right: 47%;
  //display: flex;
  //justify-content: flex-end;
  //height: 15px;
  //align-items: flex-end;
}

.rcw-attachment-preview {
  height: 80px;
  //display: flex;
  //max-width: 200px;

  .rcw-attachment-preview-img {
    //margin: auto;
    height: 70%;
    //width: 100%;
  }
}

.rcw-attachment-submitted{

  display: flex;
  justify-content: flex-end;
  width: 300px;

  .rcw-attachment-submitted-img{

    width: 50%;
  }
}
.rcw-attachment {
  background: white;
  // border-radius: 15px;
  //display: flex;
  height: 36px;
  width: 35px;
  border: none;
  padding: 0%;
  //margin-left: 0px;
  //margin-bottom: 0px;
  margin: 0;
  margin-left: 3px;
  border-radius: 20px;
  z-index: 1;
  // font-weight: bold;

  //padding-left: 10px;
  cursor: pointer;
  outline: none;
  //outline: 2px dashed rgb(200, 200, 200);

  .rcw-attachment-icon {
    height: 100%;
  }

  &:active {
    background: lightgrey;
    color: $white;
  }
}


