@import '../../../../../../scss/variables/colors';
@import '../../../../../../scss/common';

.rcw-messages-container {
  background-color: $white;
  //height: 50vh;
  //max-height: 410px;
  //max-height: 50vh;
  //overflow-y: scroll;
  //overflow-x: unset;
  padding-top: 10px;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.rcw-component{
  display: grid;
  justify-items: center;
}
.rcw-full-screen {

  .rcw-messages-container {
    @include messages-container-fs;
  }
}

// @media screen and (max-width: 800px)  {
//   .rcw-messages-container {
//     @include messages-container-fs;
//   }
// }
