@import "../../../../../../../../scss/variables/colors";

.loader {
  margin: 10px;
  display: none;
  align-items: flex-start;

  &.active {
    display: flex;
    justify-content: flex-start;
  }
}

.loader-outer-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;

  .loader-stage {
    color: #2F2F32;
  }

  .loader-partial-content-container {
    background-color: #f6f7f8;
    border-radius: 0 20px 20px 20px;
    padding: 16px;
    max-width: 70vw;
    text-align: left;
    font-family: "Source Sans Pro", "Segoe UI", sans-serif;
    font-size: 14px;
    white-space: pre-wrap;
  }

  .loader-container {
    background-color: #f6f7f8;
    border-radius: 0 20px 20px 20px;
    padding: 12px 16px;
    max-width: 36px;
    text-align: left;
    height: 16px;
    display: flex;
  }

  .loader-dots {
    display: inline-block;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background: $grey-0;
    margin-right: 2px;
    animation: bounce 0.5s ease infinite alternate;

    &:nth-child(1) {
      animation-delay: 0.2s;
      width: 8px;
      height: 8px;
      margin: 0 6px 0 0;
      opacity: 0.3;
      background-color: #a2a3a9;
    }

    &:nth-child(2) {
      width: 8px;
      height: 8px;
      margin: 0 6px 0 0;
      opacity: 0.3;
      background-color: #787d84;
    }

    &:nth-child(3) {
      width: 8px;
      height: 8px;
      margin: 0 0 0 0;
      opacity: 0.6;
      background-color: #2f2f32;
    }
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(5px);
  }
}
