.survey-container {
  text-align: center;
  width: 80%;
  height: 40vh;
  align-items: center;
  display: inline-grid;
}

.star {
  margin: 0 8px;
  cursor: pointer;
}

svg.star {
  stroke: black;
  stroke-width: 20px;
}

input[type='radio'] {
  display: none;
}

.feedback-box {
  width: 90%;
  line-height: 1.14;
  font-stretch: normal;
  letter-spacing: normal;
  resize: none;
  font-family: 'Source Sans Pro', 'Segoe UI', sans-serif;
  font-size: 14px;
  padding: 0 8px;
  max-height: 240px;
  min-height: 90px;
  border: none;
  outline: none;
  justify-content: center;
}

.feedback-box::placeholder {
  color: #b8b8b8;
  margin: 0
}

.star-container {
  display: inline-flex;
}

.feedback-text {
  font-size: 12px;
  padding-top: 20px
}

.feedback-title {
  font-size: 12px !important;
  color: #6b6b6c;
  margin: auto;
  width: 90%;
  text-align: left;
  padding-top: 20px;
  padding-bottom: 4px;
}

.feedback-text-bolded {
  margin-top: 48px;
  margin-bottom: 24px;
  font-weight: bold;
  font-size: 16px
}

button.submit-survey {
  text-transform: none;
  margin: 20px 0 40px 0;
  border-radius: 4px;
  background-color: #000000;
  padding: 6px 20px;
  color: #ffffff;
  font-size: 15px;
  font-family: 'Source Sans Pro', 'Segoe UI', sans-serif;
  border-style: none;
  /* border: 1px solid black; */
  cursor: pointer;
  width: 100%;
}

.char-count {
  text-align: right;
  margin-top: 0px;
  display: inline-block;
  color: #6b6b6c;
  width: 100%;
  font-size: 12px
}
